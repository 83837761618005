var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/rating/Rating.macro.njk"] = require( "@naturehouse/design-system/components/atoms/rating/Rating.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["templates/website/detail/partials/_review-item.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/atoms/rating/Rating.macro.njk", false, "templates/website/detail/partials/_review-item.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("ratingTemplate", t_1);
output += "\n\n<li class=\"nh-review__item nh-reviews__review\">\n    <header class=\"nh-reviews__review-header\">\n        ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "initials"),null)) {
output += "\n            <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "initials"), env.opts.autoescape);
output += "</span>\n        ";
;
}
output += "\n\n        <span class=\"nh-reviews__date\">\n            ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "arrivalDate"),null)) {
output += "\n                ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "arrivalDate"), env.opts.autoescape);
output += "\n            ";
;
}
output += "\n        </span>\n    </header>\n\n    <nh-translation-fetcher class=\"nh-reviews__review-content\">\n        <div class=\"nh-reviews__review-content-header\">\n            <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"nature_review_score"), env.opts.autoescape);
output += "</span>\n            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 18, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"render"), "ratingTemplate[\"render\"]", context, [{"value": runtime.contextOrFrameLookup(context, frame, "natureRating")}]))), env.opts.autoescape);
output += "\n        </div>\n\n        <nh-translatable-content translation-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\" current-language=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "language"), env.opts.autoescape);
output += "\" source-language=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "originalLanguage"), env.opts.autoescape);
output += "\" collection=\"review\" type=\"reviewNatureComment\">\n            <p data-role=\"current-content\" ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "isTranslated"),false) == false) {
output += "hidden";
;
}
output += ">\n                ";
output += runtime.suppressValue(env.getFilter("nl2br").call(context, env.getFilter("escape").call(context, env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "natureCommentTranslated")))), env.opts.autoescape);
output += "\n            </p>\n            <p data-role=\"source-content\" ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "isTranslated"),false) == true) {
output += "hidden";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("nl2br").call(context, env.getFilter("escape").call(context, env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "natureComment")))), env.opts.autoescape);
output += "</p>\n        </nh-translatable-content>\n\n        <div class=\"nh-reviews__review-content-header\">\n            <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"overall_rating"), env.opts.autoescape);
output += "</span>\n            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 32, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"render"), "ratingTemplate[\"render\"]", context, [{"variant": "general","value": runtime.contextOrFrameLookup(context, frame, "houseRating")}]))), env.opts.autoescape);
output += "\n        </div>\n\n        <nh-translatable-content translation-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\" current-language=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "language"), env.opts.autoescape);
output += "\" source-language=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "originalLanguage"), env.opts.autoescape);
output += "\" collection=\"review\" type=\"reviewHouseComment\">\n            <p data-role=\"current-content\" ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "isTranslated"),false) == false) {
output += "hidden";
;
}
output += ">\n                ";
output += runtime.suppressValue(env.getFilter("nl2br").call(context, env.getFilter("escape").call(context, env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "houseCommentTranslated")))), env.opts.autoescape);
output += "\n            </p>\n            <p data-role=\"source-content\" ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "isTranslated"),false) == true) {
output += "hidden";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("nl2br").call(context, env.getFilter("escape").call(context, env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "houseComment")))), env.opts.autoescape);
output += "</p>\n        </nh-translatable-content>\n\n        ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "hasLanguageSwitcher"),false)) {
output += "\n            ";
var t_4;
t_4 = "";
frame.set("label", t_4, true);
if(frame.topLevel) {
context.setVariable("label", t_4);
}
if(frame.topLevel) {
context.addExport("label", t_4);
}
output += "\n            ";
var t_5;
t_5 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"translateButtonLabel");
frame.set("buttonLabel", t_5, true);
if(frame.topLevel) {
context.setVariable("buttonLabel", t_5);
}
if(frame.topLevel) {
context.addExport("buttonLabel", t_5);
}
output += "\n            ";
var t_6;
t_6 = "source";
frame.set("version", t_6, true);
if(frame.topLevel) {
context.setVariable("version", t_6);
}
if(frame.topLevel) {
context.addExport("version", t_6);
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "isTranslated")) {
output += "\n                ";
var t_7;
t_7 = env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"currentLabel"),"currentLabel");
frame.set("label", t_7, true);
if(frame.topLevel) {
context.setVariable("label", t_7);
}
if(frame.topLevel) {
context.addExport("label", t_7);
}
output += "\n                ";
var t_8;
t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"currentButtonLabel");
frame.set("buttonLabel", t_8, true);
if(frame.topLevel) {
context.setVariable("buttonLabel", t_8);
}
if(frame.topLevel) {
context.addExport("buttonLabel", t_8);
}
output += "\n                ";
var t_9;
t_9 = "current";
frame.set("version", t_9, true);
if(frame.topLevel) {
context.setVariable("version", t_9);
}
if(frame.topLevel) {
context.addExport("version", t_9);
}
output += "\n            ";
;
}
output += "\n\n            <nh-translatable-content-switch version=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "version"), env.opts.autoescape);
output += "\">\n                <small>\n                    <span data-role=\"label\" class=\"text-gray\">\n                        ";
if(!runtime.contextOrFrameLookup(context, frame, "hasLanguageSwitcher") || runtime.contextOrFrameLookup(context, frame, "isTranslated")) {
output += "\n                            ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "label"), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n                    </span>\n\n                    <button class=\"nh-anchor nh-inline-text-button\" data-role=\"toggle\">\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "hasLanguageSwitcher") && !runtime.contextOrFrameLookup(context, frame, "isTranslated")) {
output += "\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"translateButtonLabel"), env.opts.autoescape);
output += "\n                        ";
;
}
else {
output += "\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"currentButtonLabel"), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n                    </button>\n                </small>\n            </nh-translatable-content-switch>\n        ";
;
}
output += "\n    </nh-translation-fetcher>\n</li>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["templates/website/detail/partials/_review-item.html.njk"] , dependencies)